
#bottomSection {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    width: 100%;
}

#late-payment-header {
    align-items: center;
    display: flex;
    height: 52px;
    justify-content: center;
    text-align: center;
}

#negativeCard {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 246px;
    height: 284px;
}

#negativeCard > h3 {
    color: black;
    font-size: 65px;
    font-weight: bold;
    margin: 0;
    padding: 10%;
    text-align: center;
}

#negativeCard > h5 {
    align-self: center;
    color: black;
}

#suggestionsCard {
    padding-left: '32px';
    max-height: '100%';
    margin: '0';
}

#suggestionsCard > p {
    padding-left: 20px;
}

#suggestionsContainer {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
}

.suggestionsItem {
    font-size: 23px;
    font-weight: 400 bold;
    line-height: 32px;
}
#topSection {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
}


@media screen and (min-width: 320px) and (max-width: 575px) {
    #grid-item-cards {
        width: 100%;
        max-width: 100%;
    }   
    
    #grid-item-details {
        max-width: 100%;
        width: 100%;
    }

    #topSection {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
    
    #negativeCard {
        align-items: center;
        border-radius: 12px;
        justify-content: center;
        height: 136px;
        max-width: 30%;
    }

    #negativeCard > h3 {
        color: black;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 0%;
        text-align: center;
    }
    
    #negativeCard > h5 {
        font-size: 16px;
        margin: 0;
        margin-top: 20px;
        text-align: center;
    }

    #suggestionsCard {
        align-items: center;
    }

    #suggestionsCard > p {
        text-align: center;
    }
    
    #suggestionsContainer {
        align-items: center;
    }

    .suggestionsItem {
        font-size: 17px;
        line-height: 25px;
    }

    .suggestionList {
        display: 'flex';
        flex-direction: column;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    #grid-item-cards {
        max-width: 100%;
        width: 100%;
    }   
    
    #grid-item-details {
        max-width: 100%;
        width: 100%;
    }

    #topSection {
        flex-direction: column;
    }
    
    #negativeCard {
        justify-content: center;
        max-width: 30%;
    }

}


@media screen and (min-width: 768px) and (max-width: 991px) {
    #grid-item-cards {
        max-width: 100%;
        width: 100%;
    }   
    
    #grid-item-details {
        max-width: 100%;
        width: 100%;
    }

    #topSection {
        flex-direction: column;
    }
    
    #negativeCard {
        justify-content: center;
        max-width: 30%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #grid-item-cards {
        max-width: 100%;
        width: 100%;
    }   
    
    #grid-item-details {
        max-width: 100%;
        width: 100%;
    }

    #topSection {
        flex-direction: column;
    }
    
    #negativeCard {
        justify-content: center;
        max-width: 30%;
    }
}

@media screen and (min-width: 1200px) {
    #negativeCard {
        justify-content: center;
        max-width: 31%;
    }
}