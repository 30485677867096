@media screen and (max-width: 767px) {
    #report-grid-item {
        flex: 1;
        min-width: 100%;
    }
    
    #credit-report-container{
        flex-direction: column;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #report-grid-item {
        flex: 1;
        min-width: 100%;
    }
    
    #credit-report-container{
        flex-direction: column;
    }
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
    #report-grid-item {
        flex: 1;
        min-width: 100%;
    }
    
    #credit-report-container{
        flex-direction: column;
    }
}

@media screen and (min-width: 1200px) {
    #report-grid-item {
        flex: 1;
        min-width: 100%;
    }
}