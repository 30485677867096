    .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid black;
    color: black;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #13294C;
  }
  
  .paginationActive a {
    color: white;
    background-color: #13294C;
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: grey;
  }

  .paginationDisabled a:hover {
    color: grey;
    background-color: grey;
  }