@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.my-custom-1 {
  color: white;
  stroke: red;
  fill: red;
}

.my-custom-2 {
  stroke: #32a852;
  fill: #32a852;
}

.my-custom-3 {
  stroke: blue;
  fill: blue;
}

.my-custom-4 {
  stroke: purple;
  fill: purple;
}

.ct-label {
  font-size: 15px;
  color: white;
}

.bill-name {
  font-family: 'Lato', sans-serif;
  font-size: 19px;
  margin: 0;
}

.bill-cost {
  font-family: 'Lato', sans-serif;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
}

.bill-divs {
  border-radius: 3px;
  border: 3px solid transparent;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #eebd09;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #274b73;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #3ac0c4;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #56a3a6;
}
