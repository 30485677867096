#grid-item {
    margin-bottom: 10px;
}

#report-results {
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    #grid-item {
        min-width: 50%;
    }

    #report-results {
        order: 3;
        min-width: 100%;
        flex: 1;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #grid-item {
        min-width: 50%;
    }

    #report-results {
        order: 3;
        min-width: 100%;
        flex: 1;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    #grid-item {
        min-width: 50%;
    }

    #report-results {
        order: 3;
        min-width: 100%;
        flex: 1;
    }
}

@media screen and (min-width: 1200px) {
    #grid-item {
        display: flex;
        flex: 1;
        max-height: 241px;
    }
    
    #report-results {
        display: flex;
        flex: 2;
        max-height: 244px;
        
    }
    
}